import "./index.scss";
import baffle from "baffle";
import gsap from "gsap";
import "./commonFeatures.js";

/*
// lazy load images
const imagesTolazyLoad = document.querySelectorAll(".lazy");

let intersectionOptions = {
  rootMargin: "10px",
  threshold: 0.25,
};

function lazyLoadImages(elements) {
  elements.map((entry) => {
    const element = entry.target;

    if (entry.isIntersecting) {
      element.src = element.dataset.src;
      imageObserver.unobserve(element);
    }
  });
}
let imageObserver = new IntersectionObserver(
  lazyLoadImages,
  intersectionOptions
);

imagesTolazyLoad.forEach((image) => imageObserver.observe(image));

// handle scroll icon

const scrollIcon = document.querySelector(".scroll");

const scrollAnimationTimeline = gsap.timeline({ paused: true });

scrollAnimationTimeline.from(".scroll", { x: "8rem" });

window.addEventListener("scroll", () => {
  if (window.scrollY <= 0) {
    scrollAnimationTimeline.reverse();
    setTimeout(() => {
      scrollIcon.style.display = "none";
    }, 1000);
  } else {
    scrollIcon.style.display = "block";
    scrollAnimationTimeline.play();
  }
});

// baffle footer

(function () {
  // get day

  const date = new Date();
  const day = date.getDay();
  const dayContainer = document.querySelector(".shuffle");
  const dayArray = [
    "Monday.",
    "Tuesday.",
    "Wednesday.",
    "Thursday.",
    "Friday.",
    "Saturday.",
    "Sunday.",
  ];

  if (day === 0) {
    dayContainer.innerText = "Sunday.";
  } else {
    dayContainer.innerText = dayArray[day - 1];
  }

  const elementToBaffle = document.querySelector(".shuffle");
  const initBaffle = baffle(elementToBaffle);
  initBaffle.set({
    characters: "¯_(ツ)░█▒█▒ /_/",
    speed: 120,
  });

  setInterval(() => {
    initBaffle.start();
    initBaffle.reveal(1000);
  }, 3000);
})();
 */

// Check size and adjust footer

(document.onload = () => {
  let size = window.innerWidth;
  if (size <= 650) {
    let footerText = document.querySelector(".footer__text");
    footerText.innerText = " ";
    let lottiePortrait = document.querySelector(".lottie dotlottie-player");
    lottiePortrait.style = "width: 90px";
  }
  if (size <= 450) {
    let footerParagraph = document.querySelector(".footer__container p");

    footerParagraph.innerHTML = `Copyright <span class="footer__copyright">2024.</span>`;
  }
})();
